import React, { useState } from 'react';
import logo from '../../Assets/Logo.svg';
import desktop from '../../Assets/desktop-white.svg';
import wave from '../../Assets/wave-white.svg';
import tahometer from '../../Assets/tachometer-white.svg';
import nonuser from '../../Assets/non-user-white.svg';
import './Terms.css';
import { Container, Paper, Typography  } from '@mui/material';

function Terms() {

    return (
        <main className="mp3-page">

            <section className="top-section">
                <div className="top-section-image">
                    <img src={logo} alt="Easy MP3 logo image" onClick={() => { window.location.href = '/'; }} />
                </div>

                <Container maxWidth="md" style={{ marginTop: '20px' }}>
                    <Paper style={{ padding: '30px', backgroundColor: '#f9f9f9' }}>
                        <Typography variant="h4" gutterBottom>
                            Terms of Use
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                            Effective Date: September 3, 2024
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Welcome to EASY-MP3.com. By using our services, you agree to the following Terms of Use. Please read them carefully.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            1. Acceptance of Terms
                        </Typography>
                        <Typography variant="body1" paragraph>
                            By accessing or using the Website, you agree to be bound by these Terms of Use. If you do not agree to these terms, you may not use our services.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            2. Use of Services
                        </Typography>
                        <Typography variant="body1" paragraph>
                            The Website provides a tool to convert YouTube videos to MP3 files. You are solely responsible for ensuring that your use of this tool complies with all applicable laws, including copyright laws.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            3. Copyright Compliance
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You may only use our services to convert videos that either:
                        </Typography>
                        <ul className='doted_list'>
                            <li><Typography variant="body1">You own the copyright to, or</Typography></li>
                            <li><Typography variant="body1">Are in the public domain and not protected by copyright laws.</Typography></li>
                        </ul>


                        <Typography variant="h6" gutterBottom>
                            4. Prohibited Use
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You are strictly prohibited from using our services to convert videos that are copyrighted by third parties unless you have explicit permission from the copyright holder. This includes, but is not limited to:
                        </Typography>
                        <ul className='doted_list'>
                            <li><Typography variant="body1">Music videos</Typography></li>
                            <li><Typography variant="body1">Movies</Typography></li>
                            <li><Typography variant="body1">TV shows</Typography></li>
                            <li><Typography variant="body1">Any other media content that you do not own or that is not in the public domain.</Typography></li>
                        </ul>

                        <Typography variant="h6" gutterBottom>
                            5. Disclaimer of Liability
                        </Typography>
                        <Typography variant="body1" paragraph>
                            EASY-MP3.com is a tool provided for lawful purposes only. We do not endorse or condone any unlawful activity, including copyright infringement. By using our services, you agree that you are solely responsible for your actions. We disclaim any liability for your use of the Website, including any claims of copyright infringement.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            6. User Responsibility
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You acknowledge and agree that you are responsible for determining whether a video is legally permissible to download and convert to MP3 format. EASY-MP3.com does not monitor or control the content that users convert and is not responsible for any misuse of our services.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            7. Indemnification
                        </Typography>
                        <Typography variant="body1" paragraph>
                            You agree to indemnify, defend, and hold harmless EASY-MP3.com, its affiliates, and its employees from any and all claims, liabilities, damages, and expenses, including legal fees, arising out of or related to your use of the Website or your violation of these Terms of Use.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            8. Termination
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We reserve the right to terminate or restrict your access to our services at any time, without notice, for any reason, including a violation of these Terms of Use.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            9. Changes to the Terms of Use
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We may modify these Terms of Use at any time. Any changes will be effective immediately upon posting on the Website. Your continued use of the Website following the posting of any changes constitutes your acceptance of those changes.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            10. Governing Law
                        </Typography>
                        <Typography variant="body1" paragraph>
                            These Terms of Use shall be governed by and construed in accordance with the United States Digital Millennium Copyright Act. Any legal action or proceeding related to your use of the Website shall be brought in a court of competent jurisdiction.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            11. Refunds
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Due to the nature of our service, we are unable to offer refunds. All sales are final.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            12. Contact Information
                        </Typography>
                        <Typography variant="body1" paragraph>
                            If you have any questions about these Terms of Use, please contact us at info@EASY-MP3.com
                        </Typography>

                    </Paper>
                </Container>

                <div className='spacer'></div>

            </section>
            <section className="bottom-section">
                <div className="bottom-section-upper">
                    <div className="bottom-section-upper-title">No Hidden Fees or Subscriptions.</div>
                    <div className="bottom-section-upper-text">Experience the convenience of Easy-MP3 today. Try our YouTube to MP3 converter and enjoy your favorite YouTube content in MP3 format, hassle-free!</div>
                </div>
                <div className="bottom-section-benefits-container">
                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={desktop} alt="Image displaying desktop monitor" loading='lazy' />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                User-Friendly Interface
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                No technical skills required. Our converter is designed to be easy for everyone.
                            </div>
                        </div>

                    </div>

                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={wave} alt="Image displaying wave form" loading='lazy' />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                High-Quality Audio
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                Enjoy clear and crisp audio with every conversion.
                            </div>
                        </div>

                    </div>

                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={tahometer} alt="Image displaying tachometer" />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                Fast Conversion
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                Get your MP3 files in seconds using our YouTube to MP3 converter.
                            </div>
                        </div>

                    </div>

                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={nonuser} alt="Image displaying user icon" />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                No Account Needed
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                Start converting without the hassle of signing up.
                            </div>
                        </div>

                    </div>

                </div>

                <div className='footer'>
                    <p className='footer-element element' onClick={() => { window.location.href = '/'; }}>&copy; Easy MP3</p>
                    <p className='footer-element element' onClick={() => { window.location.href = '/privacy-policy'; }}>Privacy Policy</p>
                    <p className='footer-element element' onClick={() => { window.location.href = '/terms'; }}>Terms of Use</p>
                    <p className='footer-element element' onClick={() => { window.location.href = '/copyright'; }}>Copyright</p>
                </div>
            </section>
        </main>

    );
}

export default Terms;
