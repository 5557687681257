
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home/Home';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import './App.css';
import Terms from './Pages/Terms/Terms';
import Copyright from './Pages/Copyright/Copyright';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home/>} />
      <Route path="/home" element={<Home/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/terms" element={<Terms/>} />
      <Route path="/copyright" element={<Copyright/>} />
    </Routes>
  );
}

export default App;