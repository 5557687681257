import React, { useState } from 'react';
import logo from '../../Assets/Logo.svg';
import desktop from '../../Assets/desktop-white.svg';
import wave from '../../Assets/wave-white.svg';
import tahometer from '../../Assets/tachometer-white.svg';
import nonuser from '../../Assets/non-user-white.svg';
import './Copyright.css';
import { Container, Paper, Typography, Link, List, ListItem } from '@mui/material';

function Copyright() {

    return (
        <main className="mp3-page">

            <section className="top-section">
                <div className="top-section-image">
                    <img src={logo} alt="Easy MP3 logo image" onClick={() => { window.location.href = '/'; }} />
                </div>

                <Container maxWidth="md" style={{ marginTop: '20px' }}>
                    <Paper style={{ padding: '30px', backgroundColor: '#f9f9f9' }}>
                        <Typography variant="h4" gutterBottom>
                            Copyright Claims
                        </Typography>
                        <Typography variant="body1" paragraph>
                            At EASY-MP3, we respect the intellectual property rights of others and expect our users to do the same. While we provide a convenient service for converting YouTube videos to MP3 files, it is important to note the following:
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Data and User Privacy
                        </Typography>
                        <Typography variant="body1" paragraph>
                            EASY-MP3 does not store any user data, nor are we able to see which YouTube videos a user links to be converted for download. As a result, we cannot determine whether a user converts a video that is not in the public domain or that they do not have a lawful copyright claim over.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            Reporting Unauthorized Use
                        </Typography>
                        <Typography variant="body1" paragraph>
                            If you have evidence that a person or persons have used this service without the proper legal authority, please contact us immediately. In your notification, please provide:
                        </Typography>
                        <List>
                            <ListItem><Typography variant="body1">1. The user's name.</Typography></ListItem>
                            <ListItem><Typography variant="body1">2. The user's email address.</Typography></ListItem>
                            <ListItem><Typography variant="body1">3. Any evidence supporting the claim of unauthorized use.</Typography></ListItem>
                        </List>
                        <Typography variant="body1" paragraph>
                            Upon receiving a valid claim, EASY-MP3 will take appropriate action, including blocking the user from accessing our service again.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            How to Submit a Report
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Please send your report to the following email address:
                        </Typography>
                        <Typography variant="body1"><strong>Email:</strong> <Link href="mailto:info@EASY-MP3.com">info@EASY-MP3.com</Link></Typography>

                        <Typography variant="body1" paragraph style={{ marginTop: '20px' }}>
                            We appreciate your understanding and cooperation in helping us maintain a lawful and respectful service.
                        </Typography>
                    </Paper>
                </Container>

                <div className='spacer'></div>

            </section>
            <section className="bottom-section">
                <div className="bottom-section-upper">
                    <div className="bottom-section-upper-title">No Hidden Fees or Subscriptions.</div>
                    <div className="bottom-section-upper-text">Experience the convenience of Easy-MP3 today. Try our YouTube to MP3 converter and enjoy your favorite YouTube content in MP3 format, hassle-free!</div>
                </div>
                <div className="bottom-section-benefits-container">
                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={desktop} alt="Image displaying desktop monitor" loading='lazy' />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                User-Friendly Interface
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                No technical skills required. Our converter is designed to be easy for everyone.
                            </div>
                        </div>

                    </div>

                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={wave} alt="Image displaying wave form" loading='lazy' />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                High-Quality Audio
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                Enjoy clear and crisp audio with every conversion.
                            </div>
                        </div>

                    </div>

                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={tahometer} alt="Image displaying tachometer" />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                Fast Conversion
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                Get your MP3 files in seconds using our YouTube to MP3 converter.
                            </div>
                        </div>

                    </div>

                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={nonuser} alt="Image displaying user icon" />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                No Account Needed
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                Start converting without the hassle of signing up.
                            </div>
                        </div>

                    </div>

                </div>

                <div className='footer'>
                    <p className='footer-element element' onClick={() => { window.location.href = '/'; }}>&copy; Easy MP3</p>
                    <p className='footer-element element' onClick={() => { window.location.href = '/privacy-policy'; }}>Privacy Policy</p>
                    <p className='footer-element element' onClick={() => { window.location.href = '/terms'; }}>Terms of Use</p>
                    <p className='footer-element element' onClick={() => { window.location.href = '/copyright'; }}>Copyright</p>
                </div>
            </section>
        </main>

    );
}

export default Copyright;
