import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from '../../Assets/Logo.svg';
import http from '../../Assets/http.svg';
import converting from '../../Assets/convert.svg';
import download from '../../Assets/download.svg';
import desktop from '../../Assets/desktop-white.svg';
import wave from '../../Assets/wave-white.svg';
import tahometer from '../../Assets/tachometer-white.svg';
import nonuser from '../../Assets/non-user-white.svg';
import './Home.css';

//const api = "http://localhost:8080"
const api = "https://www.easy-mp3.xyz"

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}


function Home() {

  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [fileName, setFileName] = useState("");
  const [progress, setProgress] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [title, setTitle] = useState('');
  const [paymentSuccessfull, setPaymentSuccessfull] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [scriptCalled, setScriptCalled] = useState(false);

  useEffect(() => {
    if(isMobile && !scriptCalled){
      loadNativeBannerScript()
    }
  }, [isMobile]);

  const handleSearch2 = async () => {
    if (retryCount >= 100) {
      setErrorMsg('Maximum retries reached. Please try again later.');
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      const response = await axios.get(api + '/api/Audio/Status', {
        params: { youtubeUrl: youtubeUrl }
      });

      const { title, status, progress, msg } = response.data;

      if (status == 'processing') {
        setProgress(progress);
        setTitle(title);
        setRetryCount(retryCount + 1);
        setTimeout(handleSearch2, 5000); // Check again after 1 second
      } else if (status === 'ok') {
        setTitle(title);
        setProgress(100);

        const paymentResponse = await fetch(api + '/api/Payments/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            PaymentMethod: "",
            VideoUrl: youtubeUrl,
            Type: "Ad"
          }),
        });

        const paymentIntent = await paymentResponse.json();
        handlePaymentSuccess(paymentIntent.fileUrl);

        setLoading(false);
        setRetryCount(0); // reset retry count on success
      } else {
        setErrorMsg(msg);
        setLoading(false);
      }
    } catch (error) {
      setErrorMsg('An error occurred while checking the process status.');
      setLoading(false);
    }
  };

  const loadNativeBannerScript = async () => {
    // This ensures the script is run
    const script = document.createElement('script');
    script.src = '//pl24866741.profitablecpmrate.com/42f05b3764176a5ad30a6f1de99e1890/invoke.js';
    script.async = true;
    document.body.appendChild(script);

    setScriptCalled(true);

    return () => {
      // Clean up the script on component unmount
      document.body.removeChild(script);
    };
  }

  const handleDownload = async () => {
    const fileUrl = downloadUrl;

    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Download failed:', error);
      });
  };

  const handlePaymentSuccess = (link) => {
    setDownloadUrl(api + "/files/" + link)
    setFileName(link)
    setPaymentSuccessfull(true);
  };

  return (
    <main className="mp3-page">
      <section className="top-section">

        {/* {
          !isMobile &&
            <p>
              <iframe src="//www.highperformanceformat.com/watchew?key=19b3f26ed0916a1f3ca279d105cc9521" height="90" width="728" frameBorder="0" scrolling='no'></iframe>
            </p>
        } */}

        <div className="top-section-image">
          <img src={logo} alt="Easy MP3 logo image" onClick={() => { window.location.href = '/'; }} />
        </div>

        <div className="top-section-searchbar">
          <div className='top-section-searchbar-text'>
            {progress != 0 ? title : 'Please insert a valid YouTube URL'}
          </div>
          <div className="search-container">
            {!loading && progress != 100 && (
              <>
                <input
                  type="url"
                  id="search"
                  placeholder="https://www.youtube.com/watch?v=r6DGgLBwa9U"
                  value={youtubeUrl}
                  onChange={(e) => setYoutubeUrl(e.target.value)}
                />
                <button
                  className="search-container-button"
                  onClick={handleSearch2}
                >
                  Convert
                </button>
              </>
            )}
            {loading && (
              <>
                <div className="spinner"></div>
                <p>Progress: {progress}%</p>
              </>
              // Show spinner while loading
            )}
            {/* {!loading && progress == 100 && !paymentSuccessfull && !paymentError && (
              <>
                <Elements stripe={stripePromise}>
                  <StripeForm
                    onPaymentSuccess={handlePaymentSuccess}
                    onPaymentError={handlePaymentError}
                    videoUrl={youtubeUrl}
                  />
                </Elements>

              </>
            )} */}
            {paymentSuccessfull && (
              <>
                {/* <button className="convert-again-button" onClick={handleConvertAgain}>Convert Again</button> */}
                <button className="download-button" onClick={handleDownload}>Download</button>
              </>
            )}
            {paymentError && (
              <>
                <p>Oops! Looks like something went wrong! Try again!</p>
              </>
            )}

          </div>
        </div>

        {/* {
          isMobile &&
            <div>
              <div id="container-42f05b3764176a5ad30a6f1de99e1890"></div>
            </div>
        } */}

        <div className="top-section-promo-text">
          <div className="top-section-promo-text-title">
            The Most Trusted YouTube to Mp3 Converter
          </div>
          <div className="top-section-promo-text-bottom">
            Convert your favorite YouTube videos to a downloadable mp3 file in seconds
          </div>
        </div>
        {/* {
          !isMobile &&
          <p>
            <iframe src="//www.highperformanceformat.com/watchew?key=19b3f26ed0916a1f3ca279d105cc9521" height="90" width="728" frameBorder="0" scrolling='no'></iframe>
          </p>
        } */}
        <div className="top-section-guide">
          <div className="top-section-guide-element arrow">
            <img src={http} alt="Image displaying HTTP link" width="86" height="" />
            <p className="top-section-guide-element-title">Paste the YouTube Link</p>
            <p className="top-section-guide-element-text">Copy the URL of the YouTube video you want to convert and paste it into the link box above.</p>
          </div>
          <div className="top-section-guide-element arrow">
            <img src={converting} alt="Image displaying converting icon" width="46" height="" />
            <p className="top-section-guide-element-title">Click ‘Convert’</p>
            <p className="top-section-guide-element-text">Use our YouTube to MP3 converter by clicking the “Convert” button. The process is quick and easy, ensuring your MP3 file is ready in no time.</p>
          </div>
          <div className="top-section-guide-element">
            <img src={download} alt="Image displaying converting icon" width="46" height="" />
            <p className="top-section-guide-element-title">Download Your MP3</p>
            <p className="top-section-guide-element-text">After the conversion is complete, click the “Download” button to save the MP3 file directly to your device.</p>
          </div>
        </div>
      </section>

      <section className="bottom-section">
        <div className="bottom-section-upper">
          <div className="bottom-section-upper-title">No Hidden Fees or Subscriptions.</div>
          <div className="bottom-section-upper-text">Experience the convenience of Easy-MP3 today. Try our YouTube to MP3 converter and enjoy your favorite YouTube content in MP3 format, hassle-free!</div>
        </div>
        <div className="bottom-section-benefits-container">
          <div className="bottom-section-benefits-element">

            <div>
              <img src={desktop} alt="Image displaying desktop monitor" loading='lazy' />
            </div>
            <div className="bottom-section-benefits-element-bottom">
              <div className="bottom-section-benefits-element-title">
                User-Friendly Interface
              </div>
              <div className="bottom-section-benefits-element-text">
                No technical skills required. Our converter is designed to be easy for everyone.
              </div>
            </div>

          </div>

          <div className="bottom-section-benefits-element">

            <div>
              <img src={wave} alt="Image displaying wave form" loading='lazy' />
            </div>
            <div className="bottom-section-benefits-element-bottom">
              <div className="bottom-section-benefits-element-title">
                High-Quality Audio
              </div>
              <div className="bottom-section-benefits-element-text">
                Enjoy clear and crisp audio with every conversion.
              </div>
            </div>

          </div>

          <div className="bottom-section-benefits-element">

            <div>
              <img src={tahometer} alt="Image displaying tachometer" />
            </div>
            <div className="bottom-section-benefits-element-bottom">
              <div className="bottom-section-benefits-element-title">
                Fast Conversion
              </div>
              <div className="bottom-section-benefits-element-text">
                Get your MP3 files in seconds using our YouTube to MP3 converter.
              </div>
            </div>

          </div>

          <div className="bottom-section-benefits-element">

            <div>
              <img src={nonuser} alt="Image displaying user icon" />
            </div>
            <div className="bottom-section-benefits-element-bottom">
              <div className="bottom-section-benefits-element-title">
                No Account Needed
              </div>
              <div className="bottom-section-benefits-element-text">
                Start converting without the hassle of signing up.
              </div>
            </div>

          </div>

        </div>

        <div className='footer'>
          <p className='footer-element element' onClick={() => { window.location.href = '/'; }}>&copy; Easy MP3</p>
          <p className='footer-element element' onClick={() => { window.location.href = '/privacy-policy'; }}>Privacy Policy</p>
          <p className='footer-element element' onClick={() => { window.location.href = '/terms'; }}>Terms of Use</p>
          <p className='footer-element element' onClick={() => { window.location.href = '/copyright'; }}>Copyright</p>
        </div>
      </section>
    </main>

  );
}

export default Home;