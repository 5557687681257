import React from 'react';
import logo from '../../Assets/Logo.svg';
import desktop from '../../Assets/desktop-white.svg';
import wave from '../../Assets/wave-white.svg';
import tahometer from '../../Assets/tachometer-white.svg';
import nonuser from '../../Assets/non-user-white.svg';
import './PrivacyPolicy.css';
import { Container, Paper, Typography, Link } from '@mui/material';

function PrivacyPolicy() {

    return (
        <main className="mp3-page">

            <section className="top-section">
                <div className="top-section-image">
                    <img src={logo} alt="Easy MP3 logo" onClick={() => { window.location.href = '/'; }} />
                </div>

                <Container maxWidth="md" style={{ marginTop: '20px' }}>
                    <Paper style={{ padding: '30px', backgroundColor: '#f9f9f9' }}>
                        <Typography variant="h4" gutterBottom>
                            Privacy Policy
                        </Typography>
                        <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                            Effective Date: September 3, 2024
                        </Typography>
                        <Typography variant="body1" paragraph>
                            At EASY-MP3.com, we respect your privacy and are committed to protecting it. This Privacy Policy outlines the information we do and do not collect.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            1. Data Collection
                        </Typography>
                        <Typography variant="body1" paragraph>
                            EASY-MP3.com does not collect any personal data. We do not track, store, or monitor any information about your usage of our website or services.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            2. Cookies
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We do not use cookies or any other tracking technologies on our website.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            3. Third-Party Links
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Our website may contain links to other sites. We are not responsible for the privacy practices of these sites. We encourage you to review their privacy policies.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            4. Changes to this Policy
                        </Typography>
                        <Typography variant="body1" paragraph>
                            We may update this Privacy Policy from time to time. Any changes will be posted on this page.
                        </Typography>

                        <Typography variant="h6" gutterBottom>
                            5. Contact Information
                        </Typography>
                        <Typography variant="body1" paragraph>
                            If you have any questions about this Privacy Policy, please contact us at <Link href="mailto:info@EASY-MP3.com">info@EASY-MP3.com</Link>.
                        </Typography>

                        <Typography variant="body1" paragraph>
                            By using EASY-MP3.com, you acknowledge that you have read and understood this Privacy Policy.
                        </Typography>
                    </Paper>
                </Container>

                <div className='spacer'></div>

            </section>
            <section className="bottom-section">
                <div className="bottom-section-upper">
                    <div className="bottom-section-upper-title">No Hidden Fees or Subscriptions.</div>
                    <div className="bottom-section-upper-text">Experience the convenience of Easy-MP3 today. Try our YouTube to MP3 converter and enjoy your favorite YouTube content in MP3 format, hassle-free!</div>
                </div>
                <div className="bottom-section-benefits-container">
                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={desktop} alt="Desktop monitor" loading='lazy' />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                User-Friendly Interface
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                No technical skills required. Our converter is designed to be easy for everyone.
                            </div>
                        </div>

                    </div>

                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={wave} alt="Wave form" loading='lazy' />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                High-Quality Audio
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                Enjoy clear and crisp audio with every conversion.
                            </div>
                        </div>

                    </div>

                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={tahometer} alt="Tachometer" />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                Fast Conversion
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                Get your MP3 files in seconds using our YouTube to MP3 converter.
                            </div>
                        </div>

                    </div>

                    <div className="bottom-section-benefits-element">

                        <div>
                            <img src={nonuser} alt="User icon" />
                        </div>
                        <div className="bottom-section-benefits-element-bottom">
                            <div className="bottom-section-benefits-element-title">
                                No Account Needed
                            </div>
                            <div className="bottom-section-benefits-element-text">
                                Start converting without the hassle of signing up.
                            </div>
                        </div>

                    </div>

                </div>

                <div className='footer'>
                    <p className='footer-element element' onClick={() => { window.location.href = '/'; }}>&copy; Easy MP3</p>
                    <p className='footer-element element' onClick={() => { window.location.href = '/privacy-policy'; }}>Privacy Policy</p>
                    <p className='footer-element element' onClick={() => { window.location.href = '/terms'; }}>Terms of Use</p>
                    <p className='footer-element element' onClick={() => { window.location.href = '/copyright'; }}>Copyright</p>
                </div>
            </section>
        </main>

    );
}

export default PrivacyPolicy;
